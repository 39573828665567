export const iconNames = [
    {
        name:'icon-huabanbeifen2'
    },
    {
        name:'icon-huabanbeifen5'
    },
    {
        name:'icon-huabanbeifen6'
    },
    {
        name:'icon-huabanbeifen10'
    },
    {
        name:'icon-huaban',
    },
    {
        name:'icon-huabanbeifen9'
    },
    {
        name:'icon-huabanbeifen7'
    },
    {
        name:'icon-huabanbeifen8'
    },
    {
        name:'icon-huabanbeifen13'
    },
    {
        name:'icon-huabanbeifen15'
    },
    {
        name:'icon-huabanbeifen12'
    },
    {
        name:'icon-huabanbeifen14'
    },
    {
        name:'icon-huabanbeifen21'
    },
    {
        name:'icon-huabanbeifen20'
    },
    {
        name:'icon-huabanbeifen23'
    },
    {
        name:'icon-huabanbeifen16'
    },
    {
        name:'icon-huabanbeifen19'
    },
    {
        name:'icon-huabanbeifen28'
    },
    {
        name:'icon-huabanbeifen31'
    },
    {
        name:'icon-huabanbeifen32'
    },
    {
        name:'icon-huabanbeifen26'
    },
    {
        name:'icon-huabanbeifen18'
    },
    {
        name:'icon-huabanbeifen24'
    },
    {
        name:'icon-huabanbeifen30'
    },
    {
        name:'icon-huabanbeifen40'
    },
    {
        name:'icon-huabanbeifen39'
    },
    {
        name:'icon-huabanbeifen33'
    },
    {
        name:'icon-huabanbeifen37'
    },
    {
        name:'icon-huabanbeifen42'
    },
    {
        name:'icon-huabanbeifen37'
    },
    {
        name:'icon-huabanbeifen44'
    },
    {
        name:'icon-huabanbeifen37'
    },
    {
        name:'icon-huabanbeifen41'
    },
    {
        name:'icon-huabanbeifen43'
    },
    {
        name:'icon-huabanbeifen54'
    },
    {
        name:'icon-huabanbeifen55'
    },
    {
        name:'icon-huabanbeifen36'
    },
    {
        name:'icon-huabanbeifen50'
    },
    {
        name:'icon-huabanbeifen53'
    },
    {
        name:'icon-huabanbeifen51'
    },
    {
        name:'icon-huabanbeifen48'
    },
    {
        name:'icon-huabanbeifen49'
    },
    {
        name:'icon-huabanbeifen52'
    },
    {
        name:'icon-huabanbeifen'
    },
    {
        name:'icon-huabanbeifen59'
    },
    {
        name:'icon-huabanbeifen47'
    },
    {
        name:'icon-huabanbeifen58'
    },
]